<template>
    <div class="global-content">
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="筛选条件" class="filter">
                <van-cell title="按设备编号查" :value="filter.dev.value" is-link @click="filter.dev.showPicker = true"/>
                <van-cell title="按时间段查询" :value="filter.time.value" is-link @click="filter.time.show = true"/>
                <van-row class="text-center">
                    <van-col span="12"><van-button type="primary" size="small" @click="resetData">重置</van-button></van-col>
                    <van-col span="12"> <van-button type="primary" size="small" @click="filterData">筛选</van-button></van-col>
                </van-row>
            </van-collapse-item>
        </van-collapse>
        <div class="inner">
             <div v-if="!emptyData">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" :immediate-check="false">
                    <div class="block" v-for="(item,index) in dataList" :key="index">
                        <div class="flex bottom-line">
                            <div>名称：{{item.devName}}</div>
                            <div>编号：{{item.devCode}}</div>
                        </div>
                        <div class="info">
                            <div class="type">车位变化：{{item.parkChange === 1 ? '入车' : '出车'}}</div>
                            <div class="type">电池电量：{{item.batValue}}%</div>
                        </div>
                        <!-- <div class="time top-line">地理位置：{{item.devAddress || '暂未设置地址'}}</div> -->
                        <div class="time">更新时间：{{item.uploadTime}}</div>
                    </div>
                </van-list>
             </div>
            <div v-else><van-empty description="没有更多数据了" /></div>
        </div>
        <!-- 设备编号 -->
        <van-popup v-model="filter.dev.showPicker" round position="bottom">
            <van-picker show-toolbar :columns="filter.dev.columns" @cancel="filter.dev.showPicker = false" @confirm="devConfirm" />
        </van-popup>
        <!-- 筛选时间段 -->
        <van-calendar ref="time" v-model="filter.time.show" type="range" :min-date="filter.time.minDate" :max-date="filter.time.maxDate" @confirm="timeConfirm" color="#34a53c"/>
        <tabbar />
    </div>
</template>

<script>
import tabbar from '@/components/geom/tabBar.vue'
const Today = new Date();
var Yesterday = new Date();
Yesterday.setTime(Yesterday.getTime()-24*60*60*1000);
export default {
    name:'Warn',
    components: {
        tabbar
    },
    data(){
        return{
            emptyData:false,
            loading:false,
            finished:false,
            pageNum:1,
            pageSize:10,
            dataList:[],
            initTime:[
                `${Yesterday.getFullYear()}-${Yesterday.getMonth() > 8 ? Yesterday.getMonth() + 1 : '0' + (Yesterday.getMonth() + 1)}-${Yesterday.getDate()>9?Yesterday.getDate():'0'+Yesterday.getDate()}`,
                `${Today.getFullYear()}-${Today.getMonth() > 8 ? Today.getMonth() + 1 : '0' + (Today.getMonth() + 1)}-${Today.getDate()>9?Today.getDate():'0'+Today.getDate()}`
            ],
            activeName: '',
            filter:{
                dev:{
                    devCode:'',
                    value:'',
                    showPicker:false,
                    columns:[]
                },
                time:{
                    start:'',
                    end:'',
                    minDate: new Date(2018, 0, 1),
                    maxDate: new Date(),
                    value:'',
                    show:false
                }
            },
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.getDataList();
        this.getDevInfo();
    },
    methods:{
        getDataList(){
            const data = {
                devCode:this.filter.dev.devCode ? this.filter.dev.devCode : void 0,
                beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
                endTime:this.filter.time.end ? `${this.filter.time.end} 23:59:59` :void 0,
                projectCode:this.projectCode
            }
            this.$api.GEOM.getGeomDevParkChange(this.pageNum,this.pageSize,data).then( d =>{
                if(Array.isArray(d)){
                    this.loading = false;
                    this.dataList = this.dataList.concat(d);
                    this.pageSize > d.length ? this.finished = true : void 0;
                    this.pageNum ++;
                }
            })
        },
        // 获取设备信息
        getDevInfo(){
            this.$api.GEOM.allDevInfo({
                projectCode:this.projectCode
            }).then( d => {
                d.forEach(element => {
                    element.text = element.devName
                });
                this.filter.dev.columns = d
            })
        },
        // 重置
        resetData(){
            this.filter.dev.value = '';
            this.filter.time.value='';
            this.filter.dev.devCode = '';
            this.filter.time.start = '';
            this.filter.time.end = '';
            this.$refs.time.reset();
        },
        // 筛选
        filterData(){
            this.loading = true;
            this.finished = false;
            this.activeName = '';
            this.dataList = [];
            this.pageNum = 1;
            this.getDataList();
        },
        // 筛选设备编号确认事件
        devConfirm(v){
            this.filter.dev.showPicker = false;
            this.filter.dev.value = v.text;
            this.filter.dev.devCode = v.devCode;
        },
        // 筛选时间确认事件
        timeConfirm(date) {
            const [start, end] = date;
            this.filter.time.show = false;
            this.filter.time.start = `${this.formatDate(start)}`;
            this.filter.time.end = `${this.formatDate(end)}`;
            this.filter.time.value = `${this.filter.time.start} 至 ${this.filter.time.end}`;
        },
        // 格式化日期显示
        formatDate(date) {
            return `${date.getFullYear().toString()}-${this.fillZero(date.getMonth() + 1)}-${this.fillZero(date.getDate())}`;
        },
        // 日期补零
        fillZero(str) {
            let realNum;
            str < 10 ? realNum = '0' + str : realNum = str;
            return realNum;
        },
    }
}
</script>
<style lang="scss" scoped>
.global-content{
    text-align: left;
    .text-center{
        text-align: center;
        button{
            padding: 0 20px;
            margin-top: 5%;
        }
    }
}
.van-empty{margin: 25% 0;}
.inner{
    text-align: left;
    padding-bottom: 22%;
    .block{
        padding: 4%;
        background-color: #fff;
        font-size: .8rem;
        border: 1px solid #ececec;
        margin-top: 2%;
        .bottom-line{padding-bottom: 2%;border-bottom: 1px solid #f0f0f0;}
        .top-line{padding-top: 2%;border-top: 1px solid #f0f0f0;}
        .flex{
            display: flex;
            justify-content:space-between;
            margin: 2% 0;
            font-weight: bold;
        }
        .info div{margin: 2% 0;color: #34a53c;}
        .time{color: #656565;margin-top:2%;}
    }
}
</style>